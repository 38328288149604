import { mapState, mapGetters, mapActions } from 'vuex'
import router from '../../../plugins/router'

import SContainer from '../../../components/ui/s-container'
import STitle from '../../../components/ui/s-title'
import SLink from '../../../components/ui/s-link'
import store from '@/store'
import backIcon from '../../../assets/images/icone_voltar_simples.svg'

export default {
  name: 'ReportSentSuccess',

  props: {
    reportId: {
      type: String,
      default: null,
    },
  },

  components: {
    DraftSuccess: () => import('./components/DraftSuccess'),
    IdentifiedSuccess: () => import('./components/IdentifiedSuccess'),
    ConectedSuccess: () => import('./components/ConectedSuccess'),
    SContainer,
    SLink,
    STitle,
  },

  data() {
    return {
      backIcon,
      slug: null,
      slugId: null,
      code: '',
      isConnect: false,
      newReport: {},
    }
  },

  async created() {
    if (!this.report && this.reportId) {
      this.newReport = await this.getReportById()
      store.commit('setReport', this.newReport)
    }
    const { slug, slugId } = this.company
    this.slug = router.currentRoute.params?.slug || slug
    this.slugId = router.currentRoute.params?.slugId || slugId
    this.isConnect = router.currentRoute.params?.connect
    this.code = this.report?.code
  },

  computed: {
    ...mapState([
      'company',
      'error',
      'isAnonymousUser',
      'loading',
      'report',
      'SMS',
    ]),
    ...mapGetters(['isSourceCollaborator']),

    isAnonymous() {
      return this.report?.anonymous || false
    },

    isArchive() {
      return this.report?.status === 'ARCHIVED'
    },

    isCollaborator() {
      return (
        this.isSourceCollaborator(this.report?.company_relationship) || false
      )
    },

    hasIdentifiedReport() {
      return !this.isAnonymous && !this.isArchive
    },
  },

  methods: {
    ...mapActions(['getReportById']),

    copy() {
      const element = document.getElementById('protocol')
      element.setAttribute('type', 'text')
      element.select()

      try {
        document.execCommand('copy')
        this.copyText = 'copied'
      } catch (err) {
        console.log('Oops, unable to copy') // eslint-disable-line no-console
      }

      element.setAttribute('type', 'hidden')
      window.getSelection().removeAllRanges()
    },
  },
}
